import React from "react";
import Svg, { SvgProps } from "../Svg";

const DiscordIcon: React.FC<SvgProps> = ({ color, ...props }) => {
  return (
    <Svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g id="Group">
        <path
          id="Vector"
          d="M10.076 11C10.676 11 11.162 11.45 11.151 12C11.151 12.55 10.677 13 10.076 13C9.486 13 9 12.55 9 12C9 11.45 9.475 11 10.076 11ZM13.924 11C14.525 11 15 11.45 15 12C15 12.55 14.525 13 13.924 13C13.334 13 12.849 12.55 12.849 12C12.849 11.45 13.323 11 13.924 11ZM18.891 2C20.054 2 21 2.966 21 4.163V23L18.789 21.005L17.544 19.829L16.227 18.579L16.773 20.522H5.109C3.946 20.522 3 19.556 3 18.359V4.163C3 2.966 3.946 2 5.109 2H18.89H18.891ZM14.921 15.713C17.194 15.64 18.069 14.117 18.069 14.117C18.069 10.736 16.587 7.995 16.587 7.995C15.107 6.862 13.697 6.893 13.697 6.893L13.553 7.061C15.302 7.607 16.114 8.395 16.114 8.395C15.1591 7.85699 14.1066 7.51434 13.018 7.387C12.3274 7.309 11.6299 7.31572 10.941 7.407C10.879 7.407 10.827 7.418 10.766 7.428C10.406 7.46 9.531 7.596 8.431 8.09C8.051 8.268 7.824 8.395 7.824 8.395C7.824 8.395 8.678 7.565 10.529 7.019L10.426 6.893C10.426 6.893 9.017 6.862 7.536 7.996C7.536 7.996 6.055 10.736 6.055 14.117C6.055 14.117 6.919 15.639 9.192 15.713C9.192 15.713 9.572 15.241 9.882 14.842C8.575 14.442 8.082 13.602 8.082 13.602C8.082 13.602 8.184 13.676 8.369 13.781C8.379 13.791 8.389 13.802 8.41 13.812C8.441 13.834 8.472 13.844 8.503 13.865C8.76 14.012 9.017 14.127 9.253 14.222C9.675 14.39 10.179 14.558 10.766 14.674C11.6453 14.8464 12.5494 14.8498 13.43 14.684C13.9429 14.5925 14.4433 14.4411 14.921 14.233C15.281 14.096 15.682 13.896 16.104 13.613C16.104 13.613 15.59 14.474 14.242 14.863C14.551 15.262 14.922 15.713 14.922 15.713H14.921Z"
          fill={color || "#CBCEDD"}
        />
      </g>
    </Svg>
  );
};

export default DiscordIcon;
