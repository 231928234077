import React from "react";
import Svg, { SvgProps } from "../Svg";

const TwitterIcon: React.FC<SvgProps> = ({ color, ...props }) => {
  return (
    <Svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g id="Group">
        <path
          id="Vector"
          d="M22.1618 5.65599C21.3984 5.99368 20.5888 6.21546 19.7598 6.31399C20.6336 5.79142 21.2875 4.969 21.5998 3.99999C20.7798 4.48799 19.8808 4.82999 18.9438 5.01499C18.3144 4.34157 17.4802 3.89495 16.5708 3.74457C15.6614 3.59419 14.7277 3.74848 13.9151 4.18344C13.1024 4.6184 12.4562 5.30967 12.0769 6.14978C11.6976 6.98989 11.6066 7.93177 11.8178 8.82899C10.1549 8.74564 8.52814 8.31351 7.0431 7.56065C5.55805 6.80779 4.24794 5.75104 3.19781 4.45899C2.8261 5.09744 2.63076 5.82321 2.63181 6.56199C2.63181 8.01199 3.36981 9.29299 4.49181 10.043C3.82782 10.0221 3.17844 9.84277 2.59781 9.51999V9.57199C2.59801 10.5377 2.93218 11.4736 3.54366 12.221C4.15513 12.9685 5.00629 13.4815 5.95281 13.673C5.33642 13.84 4.69012 13.8646 4.06281 13.745C4.32968 14.5762 4.84982 15.3032 5.5504 15.8241C6.25099 16.345 7.09694 16.6338 7.96981 16.65C7.10229 17.3313 6.10899 17.835 5.04669 18.1322C3.98439 18.4293 2.87394 18.5142 1.77881 18.382C3.69051 19.6114 5.9159 20.2641 8.18881 20.262C15.8818 20.262 20.0888 13.889 20.0888 8.36199C20.0888 8.18199 20.0838 7.99999 20.0758 7.82199C20.8947 7.23016 21.6014 6.49701 22.1628 5.65699L22.1618 5.65599Z"
          fill={color || "#CBCEDD"}
        />
      </g>
    </Svg>
  );
};

export default TwitterIcon;
