import React from "react";
import Flex from "../components/Box/Flex";
import Navbar from "../components/Navbar/Navbar";
import useNavbar from "../components/Navbar/useNavbar";
import { useBreakpoint } from "gatsby-plugin-breakpoints";
import { styled } from "styled-components";
import { navbarItems } from "../config/layout";
import _ from "lodash";
import { useTranslation, Link } from "gatsby-plugin-react-i18next";

const NavbarContentWrapper = styled(Flex)`
  gap: 40px;
  width: 100%;
  align-items: center;
`;

const MobileWrapper = styled(Flex)`
  flex-direction: column;
  width: 100%;
  gap: 32px;
  padding: 28px;
`;

const StyledStroke = styled(Flex)`
  width: 27px;
  height: 1px;
  background: var(--primary-color);
`;

const StyledLink = styled(Link)<{ activeIndex?: boolean; disabled?: boolean }>`
  text-decoration: none;
  text-transform: uppercase;
  transition: color 0.4s ease-in-out;
  color: #fff;
  font-size: 14px;
  white-space: nowrap;
  color: ${({ activeIndex }) =>
    activeIndex ? "var(--primary-color)" : "#fff"};
  &:hover {
    color: ${({ disabled }) => (disabled ? "#fff" : "var(--primary-color)")};
  }
  opacity: ${({ disabled }) => (disabled ? 0.7 : 1)};
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "disabled")};
`;

const Header: React.FC = () => {
  const { t } = useTranslation();
  const breakpoints = useBreakpoint();
  const isTablet = breakpoints.md;
  const { isOpen, onPresent, onDismiss } = useNavbar();

  const getCurrentPath = () => {
    if (typeof window !== "undefined") {
      return window?.location?.pathname;
    }
  };

  const headerItems = (
    <>
      {navbarItems.map((menuItem, index) => {
        return (
          <StyledLink
            key={index}
            to={menuItem.href}
            activeIndex={getCurrentPath() === menuItem.href}
            onClick={() => onDismiss()}
            disabled={_.isEmpty(menuItem.href)}
          >
            {t(`header.${menuItem.name}`)}
          </StyledLink>
        );
      })}
    </>
  );

  return (
    <Navbar
      {...{ isOpen, onPresent, onDismiss }}
      sidebarContent={<MobileWrapper>{headerItems}</MobileWrapper>}
      navbarContent={
        <>
          {!isTablet && (
            <NavbarContentWrapper>
              <StyledStroke />
              {headerItems}
            </NavbarContentWrapper>
          )}
        </>
      }
    />
  );
};

export default Header;
