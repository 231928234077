import { styled } from "styled-components";
import {
  TypographyProps,
  SpaceProps,
  ColorProps,
  LayoutProps,
} from "styled-system";
import {
  compose,
  typography,
  space,
  color,
  layout,
  position,
  background,
} from "styled-system";

type CommponTextProps = TypographyProps & SpaceProps & ColorProps & LayoutProps;

interface TextProps extends CommponTextProps {
  colored?: boolean;
  bold?: boolean;
  uppercase?: boolean;
  clickable?: boolean;
  hoverColor?: string;
  lineHeight?: number;
}

export const Text = styled.div<TextProps>`
  ${(props) =>
    props.colored
      ? `
    color: var(--primary-color);
  `
      : ""}

  &:hover {
    color: ${({ hoverColor }) => hoverColor};
  }

  transition: color 0.4s ease-in-out;
  font-weight: ${(props) => (props.bold ? "700" : "normal")};
  text-transform: ${(props) => (props.uppercase ? "uppercase" : "none")};
  cursor: ${(props) => (props.clickable ? "pointer" : "default")};
  ${compose(typography, space, color, layout, position, background)}
  font-family:"Unbounded", sans-serif;
  line-height: ${({ lineHeight }) => (lineHeight ? `${lineHeight}%` : "160%")};
`;
