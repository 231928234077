import React from "react";
import styled from "styled-components";
import { Flex } from "../Box";
import { getValidUrlToLink } from "../../utils/getValidUrlToLink";

export interface SocialMediaIconProps {
  icon: React.FC;
  href?: string;
}

const StyledIcon = styled(({ icon: Icon, ...props }) => <Icon {...props} />)`
  cursor: pointer;
`;

const SocialMediaIcon: React.FC<SocialMediaIconProps> = ({
  href,

  ...props
}) => {
  return (
    <Flex onClick={() => window.open(getValidUrlToLink(href), "_blank")}>
      <StyledIcon {...{ href }} {...props} />
    </Flex>
  );
};

export default SocialMediaIcon;
