import React from "react";
import { Flex } from "../components/Box";
import { MainWrapper } from "../components/General";
import MetaproLogo from "../components/Svg/MetaproLogo";
import GameArenaLogo from "../components/Svg/GameArenaLogo";
import { styled } from "styled-components";
import { Text } from "../components/Text/Text";
import { footerItems } from "../config/layout";
import gradient from "../images/gradient.webp";
import { getSocialIconByName } from "../utils/getSocialIconByName";
import { useTranslation } from "react-i18next";

const ContentWraper = styled(Flex)`
  justify-content: space-between;
  width: 100%;
  gap: 16px;
  position: relative;
  @media (max-width: 900px) {
    flex-direction: column;
  }
`;

const LogoWrapper = styled(Flex)`
  gap: 32px;
  @media (max-width: 600px) {
    justify-content: center;
    order: 1;
  }
`;

const RightsWrapper = styled(Flex)`
  @media (max-width: 600px) {
    order: 3;
  }
`;

const SocialsWraper = styled(Flex)`
  gap: 16px;
  @media (max-width: 600px) {
    justify-content: center;
    order: 2;
  }
`;

const Logo = styled(Flex)`
  cursor: pointer;
`;

const Footer: React.FC = () => {
  const { t } = useTranslation();

  return (
    <MainWrapper>
      <ContentWraper>
        <LogoWrapper>
          <Logo
            onClick={() => window.open(process.env.GATSBY_PGA_URL, "_blank")}
          >
            <GameArenaLogo />
          </Logo>
          <Logo
            onClick={() =>
              window.open(process.env.GATSBY_METAPRO_URL, "_blank")
            }
          >
            <MetaproLogo />
          </Logo>
        </LogoWrapper>
        <RightsWrapper>
          <Text
            uppercase
            bold
            color="var(--gray-color)"
            textAlign="center"
            fontSize="12px"
          >
            {t("general.allRightsReserved")}
          </Text>
        </RightsWrapper>
        <SocialsWraper gap="16px">
          {footerItems.map((social, index) => {
            return (
              <Flex key={index}>
                {getSocialIconByName(social.name, social.href)}
              </Flex>
            );
          })}
        </SocialsWraper>
      </ContentWraper>
    </MainWrapper>
  );
};

export default Footer;
