import React from "react";
import DiscordIcon from "../components/Svg/Socials/Discord";
import TwitterIcon from "../components/Svg/Socials/Twitter";
import YoutubeIcon from "../components/Svg/Socials/Youtube";

export const navbarItems = [
  {
    name: "home",
    href: "/",
  },
  {
    name: "simracingSchedule",
    href: "/simracing-schedule/",
  },
  {
    name: "gamingEvent",
    href: "/exhibition/",
  },
  {
    name: "location",
    href: "/location/",
  },
  {
    name: "aboutUs",
    href: "/about-us/",
  },
];

export const footerItems = [
  {
    name: "Discord",
    icon: <DiscordIcon />,
    href: process.env.GATSBY_DISCORD_URL,
  },
  {
    name: "Twitter",
    icon: <TwitterIcon />,
    href: process.env.GATSBY_TWITTER_URL,
  },
  {
    name: "Youtube",
    icon: <YoutubeIcon />,
    href: process.env.GATSBY_YOUTUBE_URL,
  },
];
