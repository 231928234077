import React from "react";
import SwipeableDrawer, {
  SwipeableDrawerProps,
} from "@mui/material/SwipeableDrawer";
import styled from "styled-components";

export const NAVBAR_HEIGHT = 90;

export const CustomDrawer = styled((props: SwipeableDrawerProps) => (
  <SwipeableDrawer {...props} disableEnforceFocus />
))<{
  bg?: string;
  customZIndex?: number;
}>`
  &.MuiDrawer-modal {
    top: ${NAVBAR_HEIGHT}px;
  }
  .MuiBackdrop-root {
    top: ${NAVBAR_HEIGHT}px;
  }
  .MuiDrawer-root {
    z-index: 1199 !important;
  }
  .MuiPaper-root {
    background-color: #0e101b;
    width: 100%;
    top: ${NAVBAR_HEIGHT}px;
    box-shadow: none;
    height: calc(100% - ${NAVBAR_HEIGHT}px);
  }
  z-index: 9999 !important;
`;
