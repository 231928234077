exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-us-index-tsx": () => import("./../../../src/pages/about-us/index.tsx" /* webpackChunkName: "component---src-pages-about-us-index-tsx" */),
  "component---src-pages-details-[id]-tsx": () => import("./../../../src/pages/details/[id].tsx" /* webpackChunkName: "component---src-pages-details-[id]-tsx" */),
  "component---src-pages-exhibition-index-tsx": () => import("./../../../src/pages/exhibition/index.tsx" /* webpackChunkName: "component---src-pages-exhibition-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-location-index-tsx": () => import("./../../../src/pages/location/index.tsx" /* webpackChunkName: "component---src-pages-location-index-tsx" */),
  "component---src-pages-pitch-contest-polygon-metparo-elympics-index-tsx": () => import("./../../../src/pages/pitch-contest-polygon-metparo-elympics/index.tsx" /* webpackChunkName: "component---src-pages-pitch-contest-polygon-metparo-elympics-index-tsx" */),
  "component---src-pages-racing-event-index-tsx": () => import("./../../../src/pages/racing-event/index.tsx" /* webpackChunkName: "component---src-pages-racing-event-index-tsx" */),
  "component---src-pages-simracing-schedule-index-tsx": () => import("./../../../src/pages/simracing-schedule/index.tsx" /* webpackChunkName: "component---src-pages-simracing-schedule-index-tsx" */),
  "component---src-pages-simracing-tournament-index-tsx": () => import("./../../../src/pages/simracing-tournament/index.tsx" /* webpackChunkName: "component---src-pages-simracing-tournament-index-tsx" */),
  "component---src-pages-sponsor-index-tsx": () => import("./../../../src/pages/sponsor/index.tsx" /* webpackChunkName: "component---src-pages-sponsor-index-tsx" */),
  "component---src-pages-web-3-games-index-ts": () => import("./../../../src/pages/web3-games/index.ts" /* webpackChunkName: "component---src-pages-web-3-games-index-ts" */),
  "component---src-views-speaker-details-index-tsx": () => import("./../../../src/views/SpeakerDetails/index.tsx" /* webpackChunkName: "component---src-views-speaker-details-index-tsx" */)
}

