import React from "react";
import { Menu, X } from "react-feather";
import styled from "styled-components";
import { useBreakpoint } from "gatsby-plugin-breakpoints";
import { Flex } from "../Box";
import { CustomDrawer } from "./CustomDrawer";
import Logo from "../Svg/Logo";
import { MainWrapper } from "../General";
import { Link } from "gatsby-plugin-react-i18next";
import LanguageSwitcher from "../../layouts/LanguageSwitcher";

const Wrapper = styled(Flex)`
  width: 100%;
  flex-direction: column;
  z-index: 999;
  position: relative;
`;

const NavbarContent = styled(Flex)`
  width: 100%;
  @media (max-width: 900px) {
    justify-content: flex-end;
  }
`;

const LogoWrapper = styled(Link)`
  align-items: center;
  cursor: pointer;
  width: max-content;
  margin-right: 2vw;
  z-index: 11;
`;

const HamburgerMenuWrapper = styled(Flex)`
  z-index: 11;
  margin-right: 16px;
  cursor: pointer;
`;

interface NavProps {
  isOpen: boolean;
  onPresent: () => void;
  onDismiss: () => void;
  navbarContent: React.ReactNode;
  sidebarContent?: React.ReactNode;
}

const Navbar: React.FC<NavProps> = ({
  navbarContent,
  sidebarContent,
  isOpen,
  onPresent,
  onDismiss,
}) => {
  const breakpoints = useBreakpoint();
  const isTablet = breakpoints.md;

  return (
    <Wrapper>
      <MainWrapper alignItems="center">
        {isTablet && sidebarContent && (
          <HamburgerMenuWrapper>
            {isOpen ? <X onClick={onDismiss} /> : <Menu onClick={onPresent} />}
          </HamburgerMenuWrapper>
        )}
        <LogoWrapper to="/">
          <Logo />
        </LogoWrapper>
        <NavbarContent>{navbarContent}</NavbarContent>
        <LanguageSwitcher />
      </MainWrapper>
      <CustomDrawer
        anchor="left"
        open={isOpen}
        onClose={onDismiss}
        onOpen={onPresent}
      >
        {sidebarContent}
      </CustomDrawer>
    </Wrapper>
  );
};

export default Navbar;
