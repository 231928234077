import React from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import "./layout.css";
import Header from "./Header";
import Footer from "./Footer";
import OGimage from "../images/OGimage.webp";
import { Script } from "gatsby";

const Layout = ({ children }: any) => {
  const { t } = useTranslation();

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Web3 Game Arena</title>
        <meta name="description" content={t("meta.description")} />
        <meta name="keywords" content={t("meta.keywords")} />
        <meta name="og:image" content={OGimage} />
        <meta name="og:image:url" content={OGimage} />
        <meta name="twitter:image" content={OGimage} />
        <meta property="og:image:type" content="image/webp" />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>
      <>
        <Header />
        <main>{children}</main>
        <Footer />
      </>
    </>
  );
};

export default Layout;
