import React from "react";
import { Flex } from "../components/Box";
import { Text } from "../components/Text/Text";
import { styled } from "styled-components";
import { useI18next } from "gatsby-plugin-react-i18next";

const StyledText = styled(Text)<{ active?: boolean }>`
  color: ${({ active }) => (active ? "var(--primary-color)" : "#fff")};
  font-size: 14px;
  cursor: pointer;
  transition: all 0.4s ease-in-out;
`;

const LanguageSwitcher = () => {
  const { languages, changeLanguage, language } = useI18next();

  return (
    <>
      {languages.map((languageCode, index) => {
        return (
          <Flex key={index}>
            {index === 1 && (
              <Text fontSize="14px" p="0 4px">
                /
              </Text>
            )}
            <StyledText
              active={language === languageCode}
              onClick={() => changeLanguage(languageCode)}
            >
              {languageCode.toUpperCase()}
            </StyledText>
          </Flex>
        );
      })}
    </>
  );
};

export default LanguageSwitcher;
