import React, { createContext, useState } from "react";

interface NavbarContext {
  onPresent: () => void;
  onDismiss: () => void;
  isOpen: boolean;
}

export const Context = createContext<NavbarContext>({
  onPresent: () => null,
  onDismiss: () => null,
  isOpen: false,
});

const NavbarProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const handlePresent = () => setIsOpen(true);
  const handleDismiss = () => setIsOpen(false);

  return (
    <Context.Provider
      value={{
        onPresent: handlePresent,
        onDismiss: handleDismiss,
        isOpen,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export default NavbarProvider;
