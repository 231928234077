import React from "react";
import SocialMediaIcon from "../components/SocialMediaIcon";
import Twitter from "../components/Svg/Socials/Twitter";
import Facebook from "../components/Svg/Socials/Facebook";
import DiscordIcon from "../components/Svg/Socials/Discord";
import YoutubeIcon from "../components/Svg/Socials/Youtube";
import InstagramIcon from "../components/Svg/Socials/Instagram";

export enum SocialPlatformName {
  TWITTER = "Twitter",
  FACEBOOK = "Facebook",
  DISCORD = "Discord",
  YOUTUBE = "Youtube",
  INSTAGRAM = "Instagram",
}

export const getSocialIconByName = (platformName?: string, href?: string) => {
  switch (platformName) {
    case SocialPlatformName.TWITTER:
      return <SocialMediaIcon icon={Twitter} {...{ href }} />;
    case SocialPlatformName.FACEBOOK:
      return <SocialMediaIcon icon={Facebook} {...{ href }} />;
    case SocialPlatformName.DISCORD:
      return <SocialMediaIcon icon={DiscordIcon} {...{ href }} />;
    case SocialPlatformName.YOUTUBE:
      return <SocialMediaIcon icon={YoutubeIcon} {...{ href }} />;
    case SocialPlatformName.INSTAGRAM:
      return <SocialMediaIcon icon={InstagramIcon} {...{ href }} />;
    default:
      return null;
  }
};
