import styled, { keyframes } from "styled-components";
import { Flex } from "../Box";

export const MainWrapper = styled(Flex)`
  width: 100%;
  max-width: 1315px;
  margin: 0 auto;
  padding: 28px;
  position: relative;
`;

export const moveRight = keyframes`
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(2px);
  }
  100% {
    transform: translateX(0);
  }
`;

export const ShadowBottom = styled(Flex)<{ shadowHeight?: number }>`
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
  pointer-events: none;
  bottom: 0;
  background: ${({ shadowHeight }) =>
    shadowHeight
      ? `linear-gradient(to top, #0e101b ${shadowHeight}%, rgba(14, 16, 27, 0) 100%)`
      : `linear-gradient(to top, #0e101b 0%, rgba(14, 16, 27, 0) 100%)`};
`;
export const ShadowLeft = styled(Flex)`
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
  pointer-events: none;
  bottom: 0;
  background: linear-gradient(to right, #0e101b 0%, rgba(14, 16, 27, 0) 50%);
`;

export const GridBackground = styled(Flex)`
  position: absolute;
  width: 100%;
  height: 1150px;
  top: -86px;
  background: linear-gradient(
        to right,
        rgba(255, 255, 255, 0.05),
        transparent 1px
      )
      0 0 / 193px 100% repeat-x,
    linear-gradient(to right, transparent, transparent) 193px 0 / 1px 100%
      repeat-x;
  @media (max-width: 600px) {
    display: none;
  }
`;
