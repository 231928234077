import { useContext, useEffect } from "react";
import { Context } from "./NavbarContext";
import { useBreakpoint } from "gatsby-plugin-breakpoints";

const useNavbar = (): {
  onPresent: () => void;
  onDismiss: () => void;
  isOpen: boolean;
} => {
  const { onPresent, onDismiss, isOpen } = useContext(Context);
  const breakpoints = useBreakpoint();
  const isTablet = breakpoints.md;

  useEffect(() => {
    if (isOpen && !isTablet) onDismiss();
  }, [isTablet, isOpen]);

  return { onPresent, onDismiss, isOpen };
};

export default useNavbar;
